<template>
  <page-header-wrapper @back="back">
    <template v-slot:breadcrumb>
      {{ formTitle }}
    </template>
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <div class="bg-white padding-lr" ref="container">
      <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left" :model="form"
                    :rules="rules">
        <a-collapse v-model="activeKey">
          <a-collapse-panel key="1" :header="$t('充值配置')" class="goods-collapse">
            <!-- 充值套餐卡片列表 -->
            <div class="recharge-list">
              <div class="recharge-card"
                   v-for="item in rechargeList"
                   :key="item.id"
                   :class="{'active': selectRechargeItem.id === item.id}"
                   @click="selectRecharge(item)">
                <div class="card-header">
                  <span class="flow-amount">{{item.flow}}G</span>
                  <span class="flow-label">流量</span>
                </div>
                <div class="card-body">
                  <div class="price">￥{{item.money}}</div>
                  <div class="desc">{{item.details}}</div>
                </div>
              </div>
            </div>

            <!-- 支付区域 -->
            <div class="payment-area">
              <!-- 支付方式选择 -->
              <div class="payment-method">
                <a-form-model-item :label="this.$t('支付方式')" prop="payType">
                  <div class="payment-tabs">
                    <div class="tab-item"
                         :class="{'active': form.payType === '1'}"
                         @click="selectPayType('1')">
                      <span class="tab-icon">
                        <i class="anticon anticon-wechat"></i>
                      </span>
                      <span class="tab-text">微信支付</span>
                    </div>
                    <div class="tab-item"
                         :class="{'active': form.payType === '2'}"
                         @click="selectPayType('2')">
                      <span class="tab-icon">
                        <i class="anticon anticon-alipay"></i>
                      </span>
                      <span class="tab-text">支付宝支付</span>
                    </div>
                  </div>
                </a-form-model-item>
              </div>

              <!-- 已选择信息展示 -->
              <div class="order-summary" v-if="selectRechargeItemFlag">
                <div class="summary-content">
                  <div class="summary-item">
                    <span class="item-label">已选套餐</span>
                    <span class="item-value">{{selectRechargeItem.flow}}G</span>
                  </div>
                  <div class="summary-item">
                    <span class="item-label">支付金额</span>
                    <span class="item-value price">￥{{selectRechargeItem.money}}</span>
                  </div>
                </div>
              </div>

              <!-- 微信支付二维码 -->
              <div class="qr-code-area" v-if="vueQrShoFlag === '1'">
                <div class="qr-title">请使用微信扫码支付</div>
                <div class="qr-wrapper">
                  <vue-qr id="qrcode"
                          :text="vueQrUrl"
                          :size="150"
                          :margin="0"
                          colorDark="#333"
                          backgroundColor="#fff"
                          :logoScale="0.21"
                  ></vue-qr>
                </div>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>

        <!-- 底部按钮 -->
        <div class="bottom-actions">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              {{ $t('购买流量') }}
            </a-button>
            <a-button @click="cancel">
              {{ $t('通用.按钮.取消') }}
            </a-button>
          </a-space>
        </div>
      </a-form-model>
    </div>
  </page-header-wrapper>
</template>

<style lang="less" scoped>
.recharge-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0;
}

.recharge-card {
  width: 220px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }

  &.active {
    border: 2px solid #1890ff;
    background: #e6f7ff;
  }

  .card-header {
    text-align: center;
    margin-bottom: 12px;

    .flow-amount {
      font-size: 24px;
      font-weight: bold;
      color: #1890ff;
    }

    .flow-label {
      font-size: 14px;
      color: #666;
      margin-left: 4px;
    }
  }

  .card-body {
    .price {
      font-size: 20px;
      color: #f5222d;
      text-align: center;
      margin-bottom: 8px;
    }

    .desc {
      font-size: 12px;
      color: #666;
      line-height: 1.5;
    }
  }
}

.payment-area {
  margin-top: 32px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);

  .payment-content {
    padding: 24px;
  }

  .payment-method {
    margin-bottom: 24px;

    .payment-tabs {
      display: flex;
      gap: 16px;

      .tab-item {
        flex: 1;
        max-width: 160px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease;
        background: #fff;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #40a9ff;
        }

        &.active {
          color: #1890ff;
          border-color: #1890ff;
          background: #e6f7ff;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            border: 8px solid #1890ff;
            border-left-color: transparent;
            border-top-color: transparent;
          }
        }

        .tab-icon {
          margin-right: 8px;

          .anticon-wechat {
            color: #07c160;
            font-size: 16px;
          }

          .anticon-alipay {
            color: #1677ff;
            font-size: 16px;
          }
        }

        .tab-text {
          font-size: 14px;
        }
      }
    }
  }

  .order-summary {
    margin-top: 24px;
    background: #f8f9fa;
    border-radius: 8px;
    padding: 20px;

    .summary-title {
      font-size: 16px;
      font-weight: 500;
      color: #262626;
      margin-bottom: 16px;
      padding-bottom: 12px;
      border-bottom: 1px solid #e8e8e8;
    }

    .summary-content {
      .summary-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;

        .item-label {
          color: #666;
        }

        .item-value {
          font-weight: 500;

          &.price {
            color: #f5222d;
            font-size: 20px;
          }
        }
      }
    }
  }

  .qr-code-area {
    text-align: center;
    padding: 24px;
    background: #f8f9fa;
    border-radius: 6px;
    margin-top: 24px;

    .qr-title {
      color: #262626;
      font-size: 16px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .wechat-icon {
        color: #07c160;
        margin-right: 8px;
        font-size: 20px;
      }
    }

    .qr-wrapper {
      background: #fff;
      padding: 16px;
      border-radius: 8px;
      display: inline-block;
      box-shadow: 0 2px 8px rgba(0,0,0,0.06);
    }
  }
}

.bottom-actions {
  margin-top: 24px;
  text-align: center;
  padding: 16px 0;
  border-top: 1px solid #f0f0f0;
}

// 保持原有的折叠面板样式
.goods-collapse > .ant-collapse-header {
  color: #007ffd !important;
  background: #e8eef9;
}

// 支付图标样式
.wechat-icon {
  color: #07c160;
  margin-right: 8px;
}

.alipay-icon {
  color: #1677ff;
  margin-right: 8px;
}

// 调整表单项样式
:deep(.ant-form-item-label) {
  font-size: 14px;
  color: #262626;
}

:deep(.ant-select-selection) {
  border-radius: 4px;
}

// 必填星号样式
:deep(.ant-form-item-required::before) {
  color: #ff4d4f;
  margin-right: 4px;
}

// 表单项标签样式
:deep(.ant-form-item-label > label) {
  color: #262626;
  font-size: 14px;
}

// 下拉选项样式
:deep(.ant-select-dropdown) {
  .ant-select-dropdown-menu-item {
    padding: 8px 12px;

    &:hover {
      background-color: #f5f5f5;
    }

    &.ant-select-dropdown-menu-item-selected {
      background-color: #e6f7ff;
      color: #1890ff;
    }
  }
}
</style>

<script>
import {getShop, addShop, updateShop,flowRecharge} from '@/api/shop/shop'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import Template from "@/views/sms/template";
import {allBrand} from "@/api/goods/brand";
import {listShopCategory} from "@/api/shop/shopCategory";
import {listShopQc} from '@/api/shop/shopQc'
import {listShopEntry} from '@/api/shop/shopEntry'
import events from "@/components/MultiTab/events";
import bus from "@/utils/bus";
import { pageFlowRechargeConfig,listFlowRechargeConfig, delFlowRechargeConfig } from '@/api/flowRechargeConfig/flowRechargeConfig'
import vueQr from 'vue-qr'
export default {
  name: 'CreateForm',
  props: {},
  components: {
    Template,
    CustomDictTag,
    vueQr
  },
  data() {
    return {
      vueQrUrl:"",
      vueQrShoFlag:"0",
      /*分类的回显*/
      categorySelected: [],
      categoryList: [{id: "0", categoryName: '', children: []}],
      shopQcList: [],
      timeOfEntryList: [],
      activeKey: [1, 2, 3, 4],
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',

      rechargeList:[],
      selectRechargeItem:{},
      selectRechargeItemFlag:false,
      queryParam: {
        flow: undefined,
        money: undefined,
        details: undefined,
        pageNum: 1,
        pageSize: 10
      },

      // 表单参数
      form: {
        id: null,
        rechargeId: null,
        payType: null,
        shopName: null,
        shopLogo: null,
        shopBackground: null,
        shopKeywords: null,
        businessTime:null,
        shopQc: null,
        timeOfEntry: null,
        shopAreap: null,
        shopAreac: null,
        shopAreax: null,
        shopAddress: null,
        longitude: null,
        latitude: null,
        shopDescription: null,
        kfPhone: null,
        companyType: null,
        settleType: null,
        shopType: null,
        shopRecommend: null,
        userId: null,
        categoryId: null,
        categoryListId: null,
        shopState: null,
        shopCloseInfo: null,
        shopCollect: null,
        attentionNum: null,
        shopAccount: null,
        settlementAccount: null,
        shopCreateTime: null,
        shopEndTime: null,
        goodsDescribeScore: null,
        shopDescribeScore: null,
        logisticsScore: null,
        shopScore: null,
        thatViews: null,
        kfAccount: null,
        alarmGoodsStock: null,
        alarmGoodsSkuStock: null,
        weatherBzj: null,
        moneyBzj: null,
        platformRatio: null,
        contactsName: null,
        contactsPhone: null,
        contactsEmail: null,
        contactsCardNo: null,
        contactsCardHand: null,
        contactsCardFront: null,
        contactsCardContrary: null,
        businessLicenceNumber: null,
        companyName: null,
        companyAreap: null,
        companyAreac: null,
        companyAreax: null,
        companyAddressDetail: null,
        socialCreditCode: null,
        authCertificate: null,
        businessSphere: null,
        businessLicenceNumberElectronic: null,
        bankCardNoType: null,
        settlementBankUserName: null,
        settlementBankAccountNumber: null,
        bankUserPhone: null,
        settlementBankAccountName: null,
        settlementBankName: null,
        settlementBankAddress: null,
        accountInstCity: null,
        accountInstProvince: null,
        createTime: null,
        remark: null,
        trademarkCertificate: null,
        shopProtocol: null,
        businessLicense: null
      },
      // 1增加,2充流量
      formType: 1,
      open: false,
      rules: {
        shopName: [
          {required: true, message: this.$t('店铺.店铺名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopLogo: [
          {required: true, message: this.$t('店铺.店铺logo') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopBackground: [
          {required: true, message: this.$t('店铺.店铺背景图') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopKeywords: [
          {required: true, message: this.$t('店铺.搜索店铺关键词') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        timeOfEntry: [
          {required: true, message: this.$t('店铺.入驻时长,外键') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopAddress: [
          {required: true, message: this.$t('店铺.详细地区') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        companyType: [
          {required: true, message: this.$t('店铺.公司申请类型1.个人2.公司') + this.$t('通用.文本.不能为空'), trigger: 'change'}
        ],
        settleType: [
          {required: true, message: this.$t('店铺.入驻类型1.线上商家2.线下商家') + this.$t('通用.文本.不能为空'), trigger: 'change'}
        ],
        payType: [
          {required: true, message: "请选择支付方式", trigger: 'change'}
        ],
        shopType: [
          {required: true, message: this.$t('店铺.是否自营 0：否 1：是') + this.$t('通用.文本.不能为空'), trigger: 'change'}
        ],
        shopRecommend: [
          {required: true, message: this.$t('店铺.是否推荐 0：否 1：是') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        userId: [
          {required: true, message: this.$t('店铺.店主id,外键 (user.id)') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopState: [
          {required: true, message: this.$t('店铺.店铺状态，0关闭，1开启，2审核中') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopCloseInfo: [
          {required: true, message: this.$t('店铺.店铺关闭原因') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopCollect: [
          {required: true, message: this.$t('店铺.店铺收藏数量') + this.$t('通用.文本.不能为'), trigger: 'blur'}
        ],
        attentionNum: [
          {required: true, message: this.$t('店铺.关注数量') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopAccount: [
          {required: true, message: this.$t('店铺.店铺账户余额') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
      }
    }
  },
  filters: {},
  created() {
    this.getCategoryList()
    this.getShopQcListList()
    this.getTimeOfEntryList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
    this.form.id = this.$route.query.id
    this.getList();
    if (this.form.id) {
      this.handleUpdate(this.form.id)
    } else {
      this.handleAdd()
    }
  },
  methods: {
    container() {
      return this.$refs.container;
    },
    back() {
      let pages = this.$store.pages;
      pages.indexOf(this.$route)
      events.$emit('close', pages.indexOf(this.$route))
      // 去更新列表页的数据
      bus.$emit('updateShopList')
      this.$router.push({path: '/shop/shop', replace: true, query:{}  })
    },
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset() {
      this.categorySelected = [];
      this.formType = 1
      this.form = {
        id: null,
        shopName: null,
        shopLogo: null,
        shopBackground: null,
        shopKeywords: null,
        shopQc: null,
        timeOfEntry: null,
        shopAreap: null,
        shopAreac: null,
        shopAreax: null,
        shopAddress: null,
        longitude: null,
        latitude: null,
        shopDescription: null,
        kfPhone: null,
        companyType: null,
        settleType: null,
        shopType: null,
        shopRecommend: null,
        userId: null,
        categoryId: null,
        categoryListId: null,
        shopState: null,
        shopCloseInfo: null,
        shopCollect: null,
        attentionNum: null,
        shopAccount: null,
        settlementAccount: null,
        shopCreateTime: null,
        shopEndTime: null,
        goodsDescribeScore: null,
        shopDescribeScore: null,
        logisticsScore: null,
        shopScore: null,
        thatViews: null,
        kfAccount: null,
        alarmGoodsStock: null,
        alarmGoodsSkuStock: null,
        weatherBzj: null,
        moneyBzj: null,
        platformRatio: null,
        contactsName: null,
        contactsPhone: null,
        contactsEmail: null,
        contactsCardNo: null,
        contactsCardHand: null,
        contactsCardFront: null,
        contactsCardContrary: null,
        businessLicenceNumber: null,
        companyName: null,
        companyAreap: null,
        companyAreac: null,
        companyAreax: null,
        companyAddressDetail: null,
        socialCreditCode: null,
        authCertificate: null,
        businessSphere: null,
        businessLicenceNumberElectronic: null,
        bankCardNoType: null,
        settlementBankUserName: null,
        settlementBankAccountNumber: null,
        bankUserPhone: null,
        settlementBankAccountName: null,
        settlementBankName: null,
        settlementBankAddress: null,
        accountInstCity: null,
        accountInstProvince: null,
        createTime: null,
        remark: null,
        trademarkCertificate: null,
        shopProtocol: null,
        businessLicense: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 充流量按钮操作 */
    async handleUpdate(id) {
      this.reset()
      this.formType = 2
      this.getCategoryList()
      getShop({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('充流量')
        //回显出分类
        console.log(this.categoryList, response.data.categoryList, response.data.categoryListId)
        this.categorySelected = response.data.categoryList.map(item => String(item))
        console.log(1111111, this.form, this.categoryList)
        return
        this.categoryList.forEach(e => {
          if (e.children) {
            e.children.forEach(child => {
              if (child.id === this.form.categoryId) {
                this.categorySelected.push(e.id)
                this.categorySelected.push(child.id)
              }
            });
          }
        });
      })
    },
    getCategoryList() {
      listShopCategory().then(response => {
        this.categoryList = response.data;
        let children = this.handleTree(response.data, 'id', 'pid')
        console.log('children', children)
        this.categoryList = children
      })
    },
    getShopQcListList() {
      listShopQc().then(response => {
        this.shopQcList = response.data
      })
    },
    getTimeOfEntryList() {
      listShopEntry().then(response => {
        this.timeOfEntryList = response.data
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      if(!this.selectRechargeItemFlag){
        this.$message.success(
          "请选择充值配置",
          3
        )
        return;
      }

      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          this.form.categoryListId = this.categorySelected
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('充流量成功')
            flowRecharge(this.form).then(response => {
              if(response.code=='20001'){
                if (response.data.payType == '1'){
                  console.log("充值连接为:"+response.data.paySign)
                  this.vueQrShoFlag = '1';
                  this.vueQrUrl = response.data.paySign;
                }else if (response.data.payType == '2'){
                  console.log("充值连接为:"+response.data.prePayUrl)
                  window.open(response.data.prePayUrl, '_blank');
                }
              }
              debugger;
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    getList () {
      this.loading = true
      listFlowRechargeConfig(this.queryParam).then(response => {
        this.rechargeList = response.data
      })
    },
    selectRecharge(item){
      this.selectRechargeItem = item;
      this.selectRechargeItemFlag = true;
      this.form.rechargeId = item.id;
    },
    // 添加支付方式选择方法
    selectPayType(type) {
      this.form.payType = type;
      // 重置支付状态
      this.vueQrShoFlag = '0';
      this.vueQrUrl = '';
    },
  }
}
</script>