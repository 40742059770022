import request from '@/utils/request'


// 查询流量充值配置列表
export function listFlowRechargeConfig(query) {
  return request({
    url: '/flowRechargeConfig/flowRechargeConfig/list',
    method: 'get',
    params: query
  })
}

// 查询流量充值配置分页
export function pageFlowRechargeConfig(query) {
  return request({
    url: '/flowRechargeConfig/flowRechargeConfig/page',
    method: 'get',
    params: query
  })
}

// 查询流量充值配置详细
export function getFlowRechargeConfig(data) {
  return request({
    url: '/flowRechargeConfig/flowRechargeConfig/detail',
    method: 'get',
    params: data
  })
}

// 新增流量充值配置
export function addFlowRechargeConfig(data) {
  return request({
    url: '/flowRechargeConfig/flowRechargeConfig/add',
    method: 'post',
    data: data
  })
}

// 修改流量充值配置
export function updateFlowRechargeConfig(data) {
  return request({
    url: '/flowRechargeConfig/flowRechargeConfig/edit',
    method: 'post',
    data: data
  })
}

// 删除流量充值配置
export function delFlowRechargeConfig(data) {
  return request({
    url: '/flowRechargeConfig/flowRechargeConfig/delete',
    method: 'post',
    data: data
  })
}
